import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import PageContainer from 'src/components/PageContainer/PageContainer';
import Box from '@mui/material/Box';
import PatternLines from 'src/components/PatternLines/PatternLines';
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot';
import { Container, Grid, Stack, useTheme } from '@mui/material';
import NotificationBlock from 'src/components/NotificationBlock/NotificationBlock';
import { getServiceStatus, setServiceStatus } from 'src/actions/adminActions';
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { eventChannel } from 'redux-saga';
import TextField from 'src/components/TextField/TextField';
import Button from 'src/components/Button/Button';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';

export default function Status() {
    const theme = useTheme();
    const status = useAppSelector(state => state.adminReducer.status);
    const dispatch = useAppDispatch();
    const [disturbanceMessage, setDisturbanceMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [userCountLimit, setUserCountLimit] = useState('');

    const init = useMemo(
        () => setDisturbanceMessage(status.disturbanceMessage),
        [status.disturbanceMessage]
    );

    const init2 = useMemo(
        () => setInfoMessage(status.infoMessage),
        [status.infoMessage]
    );

    const init3 = useMemo(
        () => setUserCountLimit(status.userCountLimit),
        [status.userCountLimit]
    );

    useEffect(() => {
        dispatch(getServiceStatus());
    }, [])


    function handleSwitchChanged(event: ChangeEvent<HTMLInputElement>, checked: boolean): void {
        dispatch(setServiceStatus({ [event.target.name]: checked }));
    }

    function handleOnChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setDisturbanceMessage(event.target.value);
    }

    function handleInfoMessageChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setInfoMessage(event.target.value);
    }

    function handleSaveDistrubanceMessage(): void {
        dispatch(setServiceStatus({ 'showDisturbanceMessage': status.showDisturbanceMessage, 'disturbanceMessage': disturbanceMessage }));
    }

    function handleSaveInfoMessage(): void {
        dispatch(setServiceStatus({ 'showInfoMessage': status.showInfoMessage, 'infoMessage': infoMessage }));
    }

    function handleUserCountLimitChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        //make sure userCountLimit is a number
        isNaN(Number(event.target.value)) ? setUserCountLimit('') :
        setUserCountLimit(event.target.value);               
        
    }

    function handleSaveUserCountLimit(): void {

        if(isNaN(Number(userCountLimit))){
            return;
        }

        dispatch(setServiceStatus({ 'enforceUserCountLimit': status.enforceUserCountLimit, 'userCountLimit': userCountLimit }));
    }

    return (
        <Box bgcolor="gray.900" position="relative">
            <PageContainer sx={{ pt: '17px' }}
                innerContainerProps={{
                    sx: { position: 'relative' },
                }}
            >
                <PatternLines height="262px" top="9px" />
                <TitleWithDot
                    typographyProps={{ variant: 'h2' }}
                    dotProps={{ bottom: '16px' }}
                    rootStackProps={{ spacing: '10px', mb: '23px' }}
                >
                    Status
                </TitleWithDot>
                
                <Container>         
                    <Stack direction="row" spacing="12px">
                        <Box
                            sx={{
                                width: 400,
                                height: 220,

                            }}
                        >
                            <NotificationBlock
                                title="Allow join"
                                description="Allow players to join challenges?"
                                SwitchProps={{ name: 'allowJoinChallenge', checked: status.allowJoinChallenge, onChange: handleSwitchChanged }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: 400,
                                height: 220,
                            }}
                        >
                            <Stack direction="column" spacing="12px">
                                <NotificationBlock
                                    title="Disturbans message"
                                    description="Show disturbance message?"
                                    SwitchProps={{ name: 'showDisturbanceMessage', checked: status.showDisturbanceMessage, onChange: handleSwitchChanged }}
                                />

                                <Box sx={{ border: `2px solid ${theme.palette.gray[700]}`, padding: '5px' }}>
                                    <TextField name='disturbanceMessage' type='text' style={{ marginTop: '14px' }} size="small" onChange={handleOnChange} value={disturbanceMessage} fullWidth={true} />

                                    <Button
                                        endIcon={<SvgIcon name="arrow-right-s-fill" sx={{ marginRight: '4px' }} />}
                                        sx={{ minWidth: '100px' }}
                                        variant="contained"
                                        size={'small'}
                                        onClick={handleSaveDistrubanceMessage}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>

                        <Box
                            sx={{
                                width: 400,
                                height: 220,
                            }}
                        >
                            <Stack direction="column" spacing="12px">
                                <NotificationBlock
                                    title="Info message"
                                    description="Show info message?"
                                    SwitchProps={{ name: 'showInfoMessage', checked: status.showInfoMessage, onChange: handleSwitchChanged }}
                                />

                                <Box sx={{ border: `2px solid ${theme.palette.gray[700]}`, padding: '5px' }}>
                                    <TextField name='infoMessage' type='text' style={{ marginTop: '14px' }} size="small" onChange={handleInfoMessageChange} value={infoMessage} fullWidth={true} />

                                    <Button
                                        endIcon={<SvgIcon name="arrow-right-s-fill" sx={{ marginRight: '4px' }} />}
                                        sx={{ minWidth: '100px' }}
                                        variant="contained"
                                        size={'small'}
                                        onClick={handleSaveInfoMessage}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                    </Container>
                    <Container>
                    <Stack direction="row" spacing="12px" marginTop='40px'>
                        <Box
                            sx={{
                                width: 400,
                                height: 220,

                            }}
                        >
                             <NotificationBlock
                                title="Allow registration"
                                description="Allow registration of new players?"
                                SwitchProps={{ name: 'allowRegister', checked: status.allowRegister, onChange: handleSwitchChanged }}
                            />

                           
                        </Box>
                        <Box
                            sx={{
                                width: 400,
                                height: 220,

                            }}
                        >
                            <NotificationBlock
                                title="Enforce whitelist"
                                description="Enforce players to be on whitelist to register?"
                                SwitchProps={{ name: 'enforceWhitelist', checked: status.enforceWhitelist, onChange: handleSwitchChanged }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: 400,
                                height: 220,

                            }}
                        >
                            <NotificationBlock
                                title="Enforce user count limit"
                                description="We can set a maxium of allowed users to be registered?"
                                SwitchProps={{ name: 'enforceUserCountLimit', checked: status.enforceUserCountLimit, onChange: handleSwitchChanged }}
                            />
                            <Box sx={{ border: `2px solid ${theme.palette.gray[700]}`, padding: '5px' }}>
                                    <TextField name='userCountLimit' type='text' style={{ marginTop: '14px' }} size="small" onChange={handleUserCountLimitChange} value={userCountLimit} fullWidth={true} />

                                    <Button
                                        endIcon={<SvgIcon name="arrow-right-s-fill" sx={{ marginRight: '4px' }} />}
                                        sx={{ minWidth: '100px' }}
                                        variant="contained"
                                        size={'small'}
                                        onClick={handleSaveUserCountLimit}
                                    >
                                        Save
                                    </Button>
                                </Box>
                        </Box>
                    </Stack>
                </Container>
                <Container>
                    <Stack direction="row" spacing="12px" marginTop='40px'>
                        <Box
                            sx={{
                                width: 400,
                                height: 220,

                            }}
                        >
                            <NotificationBlock
                                title="Allow bots"
                                description="Allow bots to play?"
                                SwitchProps={{ name: 'useBots', checked: status.useBots, onChange: handleSwitchChanged }}
                            />


                        </Box>
                    </Stack>
                </Container>
            </PageContainer>
        </Box>
    )
}