import React from 'react';
import { connect } from 'react-redux';
import ChallengeTable from './challengeTable';
import { getRunningChallenges, cancelChallengeJob, getServiceStatus, setServiceStatus, setServiceText } from '../../actions/adminActions';

var panelStyle = { paddingLeft: '20px', paddingRight: '20px' }

class Management extends React.Component {

    constructor(props) {
        super(props);
       
        this.handleJoinChange = this.handleJoinChange.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        // calling the new action creator
        this.props.localGetServiceStatus();
        this.props.localGetRunningChallenges();
    }

   

    handleJoinChange(enabled) {

        this.props.localSetServiceStatus(!enabled, this.props.serviceStatus.challengeStartEnabled, this.props.serviceStatus.useBots, this.props.serviceStatus.disturbanceText);
    }

    handleStartChange(enabled) {
      
        this.props.localSetServiceStatus(this.props.serviceStatus.joinChallengeEnabled, !enabled, this.props.serviceStatus.useBots, this.props.serviceStatus.disturbanceText);
    }

    handleBotsChange(enabled) {

        this.props.localSetServiceStatus(this.props.serviceStatus.joinChallengeEnabled, this.props.serviceStatus.challengeStartEnabled, !enabled, this.props.serviceStatus.disturbanceText);
    }

    onChange(e) {
        console.log("Changed" + e.target.value);
        if (e.target.value === '') {
            this.props.localSetServiceText(null);
        }
        else {
            this.props.localSetServiceText( e.target.value );
        }
    }

    render() {
        const { runningChallenges, localCancel, serviceStatus, working } = this.props;

        return (
            <div>{/*
                <div className="container-fluid">
                    <h2 className="panel-title">Service status</h2>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="panel" style={panelStyle}>
                                <div className="row">
                                    <div className="col-md-6">
                                       
                                        <label>
                                            <span>Join challenge</span><br />
                                            <Switch onChange={() => this.handleJoinChange(joinChecked({ serviceStatus }))} disabled={typeof serviceStatus === 'undefined' || serviceStatus.joinChallengeEnabled === null || working} checked={joinChecked({ serviceStatus })}

                                            />
                                        </label>
                                        {allowJoinText({ serviceStatus })}    
                                        <input type="text" name="disturbanceText" placeholder="Disturbance text" value={serviceStatus.disturbanceText || ''} onChange={this.onChange} />
                                    </div>

                                    <div className="col-md-6">
                                        <label>
                                            <span>Start challenge</span><br />
                                            <Switch onChange={() => this.handleStartChange(startChecked({ serviceStatus }))} disabled={typeof serviceStatus === 'undefined' || serviceStatus.challengeStartEnabled === null || working} checked={startChecked({ serviceStatus })}

                                            />
                                        </label>
                                        {allowStartText({ serviceStatus })}
                                    </div>

                                    <div className="col-md-6">
                                        <label>
                                            <span>Bots enabled</span><br />
                                            <Switch onChange={() => this.handleBotsChange(botsChecked({ serviceStatus }))} disabled={typeof serviceStatus === 'undefined' || serviceStatus.useBots === null || working} checked={botsChecked({ serviceStatus })}

                                            />
                                        </label>
                                        {allowBotText({ serviceStatus })}
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
                <div className="container-fluid">
                    <h2 className="panel-title">Running challenges  ({runningChallenges.length})</h2>
                   <div className="panel" style={panelStyle}>
                        <ChallengeTable challenges={runningChallenges} cancel={localCancel} />
                   </div>
                </div>
        */}
            </div>
        );
    }
}

const joinChecked = ({ serviceStatus }) => {
    if (typeof serviceStatus === 'undefined' || serviceStatus.joinChallengeEnabled === null)
        return false;
    else if (serviceStatus.joinChallengeEnabled) {
        return true;
    }
    else {
        return false;
    }
}

const startChecked = ({ serviceStatus }) => {
    if (typeof serviceStatus === 'undefined' || serviceStatus.challengeStartEnabled === null)
        return false;
    else if (serviceStatus.challengeStartEnabled) {
        return true;
    }
    else {
        return false;
    }
}

const botsChecked = ({ serviceStatus }) => {
    if (typeof serviceStatus === 'undefined' || serviceStatus.useBots === null)
        return false;
    else if (serviceStatus.useBots) {
        return true;
    }
    else {
        return false;
    }
}


const allowJoinText = ({ serviceStatus }) => {
    if (typeof serviceStatus==='undefined' || serviceStatus.joinChallengeEnabled === null)
        return "Unknown";
    else if (serviceStatus.joinChallengeEnabled) {
        return "Enabled";
    }
    else {
        return "Disabled";
    }
}

const allowStartText = ({ serviceStatus }) => {
    if (typeof serviceStatus === 'undefined' || serviceStatus.challengeStartEnabled === null)
        return "Unknown";
    else if (serviceStatus.challengeStartEnabled) {
        return "Enabled";
    }
    else {
        return "Disabled";
    }

}

const allowBotText = ({ serviceStatus }) => {
    if (typeof serviceStatus === 'undefined' || serviceStatus.useBots === null)
        return "Unknown";
    else if (serviceStatus.useBots) {
        return "Enabled";
    }
    else {
        return "Disabled";
    }

}

function mapStateToProps(state) {
    return {
        runningChallenges: state.adminReducer.runningChallenges,
        serviceStatus: state.adminReducer.status,
        working: state.adminReducer.working,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //function
        localGetServiceStatus: () => dispatch(getServiceStatus()),
        localGetRunningChallenges: () => dispatch(getRunningChallenges()),
        localCancel: id => dispatch(cancelChallengeJob(id)),
        localSetServiceText: (text) => dispatch(setServiceText(text)),
        localSetServiceStatus: (allowJoinChallenge, allowChallengeToStart, useBots, disturbanceMessage) => dispatch(setServiceStatus(allowJoinChallenge, allowChallengeToStart, useBots, disturbanceMessage)),
    };
}

const management = connect(mapStateToProps, mapDispatchToProps)(Management);
export { management as Management }; 