import React, { useEffect, useMemo } from 'react';
import { DataGrid, GridRowsProp, GridColDef, GridRenderCellParams, GridColumnHeaderParams, GridToolbarContainer, GridRowModes, GridRowModesModel, GridRowEditStartParams, MuiEvent, GridCallbackDetails, GridRowParams, GridEventListener, GridActionsCellItem, GridRowId, GridRowModel, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import PageContainer from 'src/components/PageContainer/PageContainer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import TitleWithDot from 'src/components/TitleWithDot/TitleWithDot';
import PatternLines from 'src/components/PatternLines/PatternLines';
import Typography from 'src/components/Typography/Typography';
import { useAppSelector, useAppDispatch } from 'src/hooks'
import { getGameTemplates, updatedGameTemplate } from 'src/actions/adminActions'
import { GameId, GameMode, GameModeFortnite, GameModeFrontland, GameTemplateType, TokenType } from 'src/helpers/types';
import CoinImageWithAmount from 'src/components/CoinImageWithAmount/CoinImageWithAmount';
import { PNG_ICONS } from 'src/assets/icons/icons';
import ImageWithTitleSmall from 'src/components/ImageWithTitleSmall/ImageWithTitleSmall';
import { showErrorPopup, showSuccessPopup } from 'src/actions/dialogActions';
import { updateGameTemplate,deleteGameTemplate } from 'src/sagas/apiSagas';
import TextField from 'src/components/TextField/TextField';
import { getTokenImgSrc } from 'src/helpers/commonData';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import SvgIcon from 'src/components/SvgIcon/SvgIcon';
import { useNavigate } from 'react-router';
import { formatTokenToString } from 'src/utils/tokenUtil';


interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = 0;
    setRows((oldRows) => [...oldRows, { id, name: "", age: "", isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" }
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function CustomEditComponent(props: GridRenderEditCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <TextField style={{ marginTop: '14px' }} size="small" value={value} onChange={handleValueChange} />;

}

function CustomCurrencyDropdownEditComponent(props: GridRenderEditCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as string; // The new value selected by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  // Check if the field is the one that should have the enum values
  const tokenTypes = Object.values(TokenType);
    return (
      <Select
        value={value}
        onChange={handleValueChange}
        style={{ marginTop: '0px', height: '36px', borderRadius: '0px' }}
      >
        {tokenTypes.map((tokenType) => (
          <MenuItem key={tokenType} value={tokenType}>
            {tokenType}
          </MenuItem>
        ))}
      </Select>
    ); 
}

function CustomGameModeDropdownEditComponent(props: GridRenderEditCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as string; // The new value selected by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

    let tokenTypes;
    const gameId = apiRef.current.getRow(id).intGameId; 
    if(gameId === GameId.FRONTLAND) {
        tokenTypes = Object.values(GameModeFrontland);
    }
    else if(gameId === GameId.FORTNITE) {
      tokenTypes = Object.values(GameModeFortnite);
    }
    else {
        tokenTypes = Object.values(GameMode);
    }

    return (
      <Select
        value={value}
        onChange={handleValueChange}
        style={{ marginTop: '0px', height: '36px', borderRadius: '0px' }}
      >
        {tokenTypes.map((tokenType) => (
          <MenuItem key={tokenType} value={tokenType}>
            {tokenType}
          </MenuItem>
        ))}
      </Select>
    ); 
}

const useMutation = () => {
  return React.useCallback(
    (gameTemplate: Partial<GameTemplateType>) =>
      new Promise<Partial<GameTemplateType>>((resolve, reject) => {
        console.log("gameTemplate=" + JSON.stringify(gameTemplate));

        updateGameTemplate(gameTemplate).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      }),
    [],
  );
};

export default function GameTemplates() {
  const mutateRow = useMutation();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const columns: GridColDef[] = [
    {
      field: 'intGameId',
      headerName: 'Game',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return params.value === GameId.FRONTLAND ?
          <ImageWithTitleSmall icon={PNG_ICONS.frontland} text="frontland" mobileHideTitle={true} />
          :
          <ImageWithTitleSmall icon={PNG_ICONS.fortnite} text="fortnite" mobileHideTitle={true} />
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Game</Typography>
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Name</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditComponent {...params} />
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      editable: true,
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Description</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditComponent {...params} />
      ),
    },
    {
      field: 'gameType',
      headerName: 'Type',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Type</Typography>
      },
    },
    {
      field: 'gameMode',
      headerName: 'Mode',
      editable: true,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Mode</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomGameModeDropdownEditComponent {...params} />
      ),
    },   
    {
      field: 'fee',
      headerName: 'Fee',
      editable: true,
      width: 80,
      renderCell: (params: GridRenderCellParams) => {
          return <CoinImageWithAmount  avatarProps={{ src: getTokenImgSrc(params.row.tokenType)}} amount={`${formatTokenToString(params.value,params.row.tokenType)}`} />
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Fee</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditComponent {...params} />
      ),
    },
    {
      field: 'tokenType',
      headerName: 'tokenType',
      editable: true,
      width: 80,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Token</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCurrencyDropdownEditComponent {...params} />
      ),
    },
    {
      field: 'prizeFund',
      headerName: 'prizeFund',
      editable: true,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
          return <CoinImageWithAmount  avatarProps={{ src: getTokenImgSrc(params.row.prizeFundTokenType)}} amount={`${formatTokenToString(params.value,params.row.prizeFundTokenType)}`} />
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">PrizeFund</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditComponent {...params} />
      ),
    },
    {
      field: 'prizeFundTokenType',
      headerName: 'prizeFundTokenType',
      editable: true,
      width: 80,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">P token</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomCurrencyDropdownEditComponent {...params} />
      ),
    },
    {
      field: 'maxParticipans',
      headerName: 'maxParticipans',
      editable: true,
      width: 60,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Max Participants</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditComponent {...params} />
      ),
    },
    {
      field: 'isTournament',
      headerName: 'isTournament',
      type: 'boolean',
      editable: true,
      width: 80,

      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Tournament</Typography>
      },
    },
    {
      field: 'roundLength',
      headerName: 'roundLength',
      editable: true,
      width: 60,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Round Length</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditComponent {...params} />
      ),
    },
    {
      field: 'pauseLength',
      headerName: 'pauseLength',
      editable: true,
      width: 60,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography variant="bodySmall" color="gray.50">{params.value}</Typography>
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Pause</Typography>
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <CustomEditComponent {...params} />
      ),
    },
    {
      field: 'disabled',
      headerName: 'disabled',
      type: 'boolean',
      editable: true,
      width: 80,

      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Disabled</Typography>
      },
    },
    {
      field: 'highlighted',
      headerName: 'highlighted',
      type: 'boolean',
      editable: true,
      width: 80,

      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Highlighted</Typography>
      },
    },
    {
      field: 'hidden',
      headerName: 'hidden',
      type: 'boolean',
      editable: true,
      width: 80,

      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Hidden</Typography>
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <Typography variant="labelSmall">Actions</Typography>
      },
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {

          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />
        ];
      }
    }

  ];

  function handleSaveClick(id: GridRowId) {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  }
  function handleCancelClick(id: GridRowId) {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });


  }
  function handleEditClick(id: GridRowId) {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  }
  function handleDeleteClick(id: GridRowId) {
    deleteGameTemplate(id).then(() => {
      dispatch(showSuccessPopup('Deleting template', 'Deleted!'));
      dispatch(getGameTemplates());
    }).catch((error) => {
      dispatch(showErrorPopup('Error deleting', error.message));
    })
  }

  function isUpdated(newRow: GridRowModel, oldRow: GridRowModel): boolean {
    return Object.keys(newRow).some((field) => newRow[field] !== oldRow[field]);
  }

  function isValid(newRow: GridRowModel): boolean {
    var notEmpty = Object.keys(newRow).every((field) => {
      if (field === 'id' || field === 'isNew' || field === 'roundLength'  || field === 'pauseLength') {
        return true;
      }
      return newRow[field] !== '';
    });

    //Fortnite can not be a tournament
    console.log("newRow.isTournament=" + newRow.isTournament);
    console.log("newRow.gameId=" + newRow.intGameId);
    if(newRow.isTournament && newRow.intGameId===GameId.FORTNITE) {
      return false;
    }

    return notEmpty && !isNaN(newRow.fee) && !isNaN(newRow.prizeFund) && !isNaN(newRow.maxParticipans);
  }

  function getUpdatedValues(newRow: GridRowModel, oldRow: GridRowModel): Partial<GameTemplateType> {
    var keys = Object.keys(newRow).filter((field) => newRow[field] !== oldRow[field]);
    var result = {} as Partial<GameTemplateType>;
    keys.forEach((key) => {
      result[key as keyof Partial<GameTemplateType>] = newRow[key];
    })

    //Must add id since it does not change
    result.id = newRow.id;

    //We must send all these values since they are set togheter
    if(result.fee || result.tokenType || result.prizeFund || result.prizeFundTokenType  ||result.maxParticipans) {
        result.fee = result.fee ? result.fee : oldRow.fee;
        result.tokenType = result.tokenType ? result.tokenType : oldRow.tokenType;
        result.prizeFund = result.prizeFund ? result.prizeFund : oldRow.prizeFund;
        result.prizeFundTokenType = result.prizeFundTokenType ? result.prizeFundTokenType : oldRow.prizeFundTokenType;
        result.maxParticipans = result.maxParticipans ? result.maxParticipans : oldRow.maxParticipans;
    }
    if(result.isTournament || result.roundLength || result.pauseLength) {
        result.isTournament = result.isTournament!==undefined ? result.isTournament : oldRow.isTournament;
        if(result.isTournament) {
          result.roundLength = result.roundLength ? result.roundLength : oldRow.roundLength;
          result.pauseLength = result.pauseLength ? result.pauseLength : oldRow.pauseLength;
        }
    }
    return result;
  }


  function getUpdatedFields(newRow: GridRowModel, oldRow: GridRowModel): string[] {
    return Object.keys(newRow).filter((field) => newRow[field] !== oldRow[field]);
  }

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {

      if (!isUpdated(newRow, oldRow)) {
        return oldRow;
      }

      if (!isValid(newRow)) {
        dispatch(showErrorPopup('Error saving', 'Invalid fields'));
        return oldRow;
      }

      if(newRow.isTournament===false){
        newRow.roundLength = undefined;
        newRow.pauseLength = undefined;
      }

      const updatedRow = { ...newRow, isNew: false };
      const toSave = getUpdatedValues(newRow, oldRow);

      try {
        const response = await mutateRow(toSave);
        dispatch(showSuccessPopup('Saving template', 'Saved!'));
        dispatch(updatedGameTemplate(response));
        return updatedRow;
      } catch (error: any) {
        dispatch(showErrorPopup('Error saving', error.message));
        return oldRow;
      }
      //setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    },
    [mutateRow],
  );

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    dispatch(showErrorPopup('Error saving', error.message));
  }, []);


  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getGameTemplates());
  }, [dispatch])

  const gameTemplates = useAppSelector(state => state.adminReducer.gameTemplates);
  const gameTemplatesRows = useMemo(() => {
    return gameTemplates.map((gameTemplate: GameTemplateType) => {
      return {
        id: gameTemplate.id,
        name: gameTemplate.name,
        description: gameTemplate.description,
        fee: gameTemplate.fee,
        tokenType: gameTemplate.tokenType,
        prizeFund: gameTemplate.prizeFund !== undefined ? gameTemplate.prizeFund : 0,
        prizeFundTokenType: gameTemplate.prizeFundTokenType!==undefined ? gameTemplate.prizeFundTokenType:TokenType.CT,
        intGameId: gameTemplate.intGameId,
        gameType: gameTemplate.gameType,
        gameMode: gameTemplate.gameMode,
        maxParticipans: gameTemplate.maxParticipans,
        isTournament: gameTemplate.isTournament,
        roundLength: gameTemplate.roundLength,
        pauseLength: gameTemplate.pauseLength,
        disabled: gameTemplate.disabled,
        highlighted: gameTemplate.highlighted,
        hidden: gameTemplate.hidden
      }

    })
  }, [gameTemplates])


  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };
  const navigate = useNavigate();
  return (
    <Box bgcolor="gray.900" position="relative">
      <PageContainer sx={{ pt: '17px' }}
        innerContainerProps={{
          sx: { position: 'relative' },
        }}
      >
        <PatternLines height="262px" top="9px" />
        <TitleWithDot
          typographyProps={{ variant: 'h2' }}
          dotProps={{ bottom: '16px' }}
          rootStackProps={{ spacing: '10px', mb: '23px' }}
        >
          Game Templates
        </TitleWithDot>
        <Button
            endIcon={<SvgIcon name="edit-line" sx={{ marginRight: '4px' }} />}
            sx={{ minWidth: '100px' , marginBottom: '6px'}}
            variant="contained"
            size={ 'small'}
            onClick={() => navigate('/templates/create')}
            >
            Create new
        </Button>

        <DataGrid
          disableColumnMenu rows={gameTemplatesRows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          sx={{
            width: '115%',
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
        />

      </PageContainer>
    </Box>

  );
}